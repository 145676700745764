.PaastoPrev{
    display: flex;
    justify-content: space-between;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    margin-bottom:1em;
    font-size: 2em;
    
}
.PaastoEnd{
    display: flex;
    justify-content: space-evenly;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    /* margin-bottom:1em; */
    font-size: 1.5em;
    border-top: 0.5px rgba(153, 153, 153, 0.267)solid;
    
    
  }
  
  .newstyling{
    padding: 1%;
    padding-top: 2%;
    color: #797979;
    opacity: 0.2;
  }
  .newstyling:hover{
    color: #393939;
    opacity: 1;
  }
  

.paasto-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    /* position: absolute;
      height: fit-content;
      font-family: 'Roboto';
      line-height: 170%;
      top: 14%;
      left: 17%;
      width: 50%; */
  }
.paasto-text h3{
    text-align: center;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(253, 130, 125);
}
.paasto-text b{
  display: block;
  text-align: center;
  padding: 5%;
  /* font-family: 'lato'; */
  font-weight: normal;
  font-size: 2.15rem;
  color: rgb(253, 130, 125);
}
.paasto-text h5{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(250, 145, 141);
}
.paasto-text h6{
    font-weight: medium;
    text-align: center;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #383838;
}
.paasto-text p{
    font-weight: normal;
    color: #797979;
}

.paasto-text img {
    border-radius: 14px;
}
.paasto-text blockquote {
    color: #797979;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em rgb(252, 231, 230) solid;
  }

img[alt=PaastoScreen1] {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;

}
img[alt=PaastoScreenL]{
position: relative; 
right: 30%;   
width: 170%;
    
  }
img[alt=PaastoScreen3]{
    width: 100%;
    /* position: relative */

}
img[alt=PaastoScreen2] {
    position: relative;
    left: 23%; 
    width: 50%
}
img[alt=Paasto-logo] {
    position: relative;
    left: 20%;
    width:60%;
  }
img[alt=PaastoResults]{
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    width:70%;
}
img[alt=PaastoScreenPen]{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

  