.myName{
    color: #383838;
    font-size: 2em;
    font-weight: 400;
    padding-bottom: 8%;
}
.about-flex{
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top:-4%;
    max-width: 80%;
    /* padding-bottom: 5%; */
}
.Intro-About{
    font-family: "Cairo";
    /* margin: auto; */
    /* margin-top: 3em; */
    color: #797979;
    margin-top: 4%;
    width: 50%;
    line-height: 170%;
}
.for-contact{
   
    display: flex;
    flex-direction: column;
    
}
.paints{
    font-family: 'Cairo';
    font-size: 1em;
    color:#797979;
}
.PaintsPlacement{
    display: flex;
    width: 79%;
    justify-content: flex-end;
    
}
.largerpaints{
    font-family: 'Cairo';
    font-size: 2em;
    font-weight: 400;
    color:#383838;
}
.outerGrid{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-content: center;
   margin-left: 14.7%;
  
   
   
}
.imagesAbout{
    width:250px;
    height: 300px;
    padding: 3%;
    border-radius: 50px;
}
.imagesGanesha{
    width:450px;
    height: 350px;
    padding: 3%;
    border-radius: 50px;
}
.Contacts{
    margin-left: 17%;
}
.ContactsLinks{
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 11.5%;
    
}
.ContactsLinks a{
    border: #000000 1.5px solid;
    padding: 0.5%;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
}
.lastPara{
    font-family: "Cairo";
    color: #797979;
    margin-left: 17%;
    width: 40%;
    line-height: 170%;
    padding-bottom: 3%;

}