#descIllus{
        
        color: #ffffff;
        font-size: 14px;
        line-height: 1.9;
}
.tileIllus:hover #descIllus{
    color: #919191;
    /* padding-left: 4%; */
    /* padding-bottom:5%; */
    font-size: 14px;
    line-height: 1.5;
    transition: .3s ease-in;
  }
.tileIllus {
    /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05); */
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
      opacity 0.2s ease-out;
    transition-delay: 0.1s;
    border-radius: 4px;
    transform: translateZ(0);
    background-color: #fff;
    overflow: hidden;
    /* height: 100%; */
    /* margin-top: 3%; */
  }
  .descriptionText{
    font-family: 'Cairo';
    /* margin-top: -35px; */
    margin-left: 'auto';
    margin-right: 'auto';
    color:#797979;
     width: 70%;
     font-size: 1rem;
     line-height: 2;
    }