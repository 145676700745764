.progress-container {
    z-index: 100;
    position: fixed;
    top: 55%;
    transform: translateY(-50%);
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Roboto';
    display: block;
    transition: all 0.3s cubic-bezier(0.7, 0.3, 0, 1);
    border-radius: 2px;
    border: 1px rgba(202, 202, 202, 0.521) solid;

}

.progress-container a {
      display: block;
      text-align: left;
      cursor: pointer;
      margin: 0.5rem 1rem;
      opacity: 0.3; }

.progress-container:hover {
    background-color: white; }
  
.progress-responsive-position {
      right: 5%; }

.progress-container a:hover {
        opacity: 1; }

.progress-container .circle {
          display: none;
          margin-left: 0.5rem; }

.progress-container .circle:before {
          content: '\25CF'; }
        
.progress-container .scroll-active {
         opacity: 1;
         color: black;
         }

.label {
     text-align: right; }
  
  