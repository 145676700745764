
.App {
  text-align: center;
}
.MuiTabs-flexContainer {
  display: flex;
  justify-content: flex-end;
}
.nameInCorner{
  margin-right:10rem;
}
u {    
  border-bottom: 2px dotted rgb(105, 105, 105);
  text-decoration: none;
}
a {
  text-decoration: none;
  color: rgb(58, 58, 58);
  font-family: "Cairo";
}
.noscroll{
  overflow: hidden;

  
}
.waves{
  transform: scaleY(0.5);
  transform-origin: -1 0;
  margin-top: -5.5em;
  
  /* overflow-x: hidden; */
  overflow: hidden;
}
.waves path {
  animation: 7s ease-in-out infinite alternate waveAnimateA;
  
}
.waves path:nth-child(2) {
  animation: 7s ease-in-out infinite alternate waveAnimateB;
}
.waves path:last-child {
  animation: 7s ease-in-out infinite alternate waveAnimateC;
}
@keyframes waveAnimateA {
  0% {
    transform: translate(-80px, 30px);
    fill: #fff9c4
  }
  100% {
    transform: translate(80px, 0);
    fill: #f0f4c3;
  }
}
@keyframes waveAnimateB {
  0% {
    transform: translate(-80px, 15px);
    fill: rgb(241, 240, 221);
  }
  100% {
    transform: translate(80px, 0);
    fill: #FFFDE7;
  }
}
@keyframes waveAnimateC {
  0% {
    transform: translate(-20px, 15px);
    fill:#fff59d;
  }
  100% {
    transform: translate(-80px, 10px);
    fill:#e6ee9c;
  }
}
.tabsRec{
  display: flex;
  justify-content: flex-end;
  
}
.Desc{
  padding: 10%;
  line-height: 200%;
  color: #797979 ;
}
.website{
  padding: 0.5%;
  cursor: pointer;
}
.bannerSize {
  display: block;
  width: 90%;
}
.contact {
  font-family: 'Cairo';
  position: absolute;
  height: fit-content;
  font-style: italic;
  padding-top: 2%;
  line-height: 200%;
  
  width: 50%;
  /* transform: translateZ(-100); */
  transition: all 0.3s;
}
.contact a:hover {
  display: block;
  font-family: 'Cairo';
  /* background: rgb(196, 196, 196);
  opacity: 0.3; */
  height: 30px;
  width: auto;
  font-weight: bold;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: translate(20px);
  /* transform: translateZ(-100); */
  transition: all 0.3s;
  border-radius: 5px;
}


/* .contact a:before
{
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0px;
    top: 0px;
    content: '';
    background: #000;
    opacity: 0.3;
    transition: all 0.3s;
}
.contact a:hover:before
{
    width: 100%;
} */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.Menu-Items {
  padding: 20px;
}
.Menu-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.project-navbar {
  background-color: #464646;
}

.project-navbar {
  background-color: #464646;
}

.about-text {
  font-family: 'Cairo';
  position: absolute;
  height: fit-content;
  line-height: 170%;
  top: 34%;
  left: 21%;
  width: 50%;
}

.pull-right {
  float: right !important;
}

.recent-all-tabs{
  display: flex;
  justify-content: flex-end;
}

.tile {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
    opacity 0.2s ease-out;
  transition-delay: 0.1s;
  border-radius: 4px;
  transform: translateZ(0);
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  /* margin-top: 3%; */
}
.tile:hover {
  opacity: 1 !important;
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  transform: translate(0, -4px);
  z-index: 999;
  

}
.tile:hover #toRead{
  color: #919191;
  padding-left: 4.8%;
  font-style: italic;
  padding-bottom:5%;
  font-size: 14px;
  transition: .3s ease-in;
}

.tile-desc {
  
  font-family: 'Cairo';
  line-height: 170%;
  padding: 30px 15px 20px 15px;
}
.tile-tags{
  font-family: 'Cairo';
  background: rgba(105, 105, 105, 0.144);
  color: #474747;
  border-radius: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  font-size: 0.7em;
  padding-right: 15px;
  margin-right: 15px;
  /* padding-bottom: 49px; */
}
.tags{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: #797979;
  padding-left: 15px;
}
.title-tags{
  font-family: 'Cairo';
  background: #ffffff;
  border-radius: 5px;
  /* padding: 15px; */
  color: #797979;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -45px;
  display: block;
  position: absolute;
  font-size: 1.2em;
}
.card-height{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 35px;
}
.time-read{
  display: block;
  padding-left: 80%;
  font-style: italic;
  width:100%;
 
  /* border: #000000 solid; */
  color: #aaa9a9;
  font-size: 0.8em;
}
hr{
  width: 100%;
  opacity: 0.1;
  color:aliceblue;
}
.text-link:hover {
  /* background-color: #636363;
  transition: all 0.25s cubic-bezier(0.1, 0.29, 0, 1);
  opacity: 1; 
  transition: all 0.25s cubic-bezier(0.1, 0.29, 0, 1);
  padding: 1rem;
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.1);
  border-radius: 10px;  */
  /* background-color: #a0a0a0;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent; */
}
.navbar-background {
  background-color: #fffefe;
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: #000000;
  padding: 2%;
}

.project-nav-background {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 3%;
  width: 100%;
  position: fixed;
  top: 5%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.concepts-used {
  position: fixed;
  left: 60%;
  width: 100%;
  bottom: 50%;
}
.textMuted {
  /* display: flex; */
  text-align: left;
  align-items: initial;
  padding-left: 16%;
  padding-bottom: 1%;
  margin: auto;
  justify-content: space-around;
  color: grey;
}
.text {
  text-align: left;
  padding-left: 16%;
  padding-bottom: 3%;
  /* display: flex; */
  margin: inherit;
  justify-content: space-around;
}

.share {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 26px;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s 0.2s;
  z-index: 2;
  display: block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 12px;
  color: #4f575b;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 5px;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: color 0.35s, background-color 0.35s;
}
[className^="icon-"],
[className*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.bottom-text {
  color: rgba(179, 192, 200, 0.8);
  opacity: 0;
  font-size: 11px;
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  text-align: left;
  transition: opacity 0.35s;
}
.column1 {
  padding-left: 100px;
}
.iconList {
  position: fixed;
  width: 35%;
  transform: translate(168%, 2900%);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.LinkedIn {
  color: #0077b5;
  size: 50px;
}
.Facebook {
  color: #3c5a99;
}
.Mail {
  color: #0072c6;
}

.name {
  position: fixed;
  top: 20%;
  left: 24%;
}
.paintingStuff {
  display: block;
  position: absolute;
  left: 33%;
  top: 10%;
}
.about {
  display: inline;
  overflow: hidden;
  position: fixed;
  width: 35%;
  transform: translate(40%, 180%);
  max-height: 800px;
}
.Intro {
  font-family: "Cairo";
  margin: auto;
  margin-top: 3em;
 
  color: #797979 ;
  /* margin-left: 8%;  */
  width: 50%;
  line-height: 170%;
}
.Intro h3{

  color:#393939;
}
.Contents {
  margin-top: 10rem;
  margin-left: 15rem;
  width: 100%;
}
.contact-list {
  font-family: 'Cairo';
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;

  display: flex;
  flex-direction: column;

  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.replaceWithFooter{
  padding-bottom: 3%;
}
.footer{
  border-top: #cccccc 0.5px solid;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'Cairo';
  display: flex;
  justify-content: space-evenly;
    /* margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    margin-bottom:1em;
    font-size: 1.5em; */
}
.head-me{
  width: 50%;
}
.currentFooter{

  color: #383838;

}
@media screen and (min-width: 400px){
  .avatar{
    width: 100%;
  }
}
.avatar{
  width: 40%;
  /* margin-left: auto;
  margin-right:auto; */

}
.landing-banner h3{
  margin-right: auto;
  font-weight: 400;
  font-size: 3.7rem;
  margin-bottom: -20px;
  
  color: #383838;
  font-family: 'Neucha';
}
.workMargins{
  margin-top: -144px;
}
.projectsBar{
  font-family: 'Cairo';
font-weight: 800;
color: #797979;
position:fixed;
padding-top: 2%;

}
#toRead{
  color: #ffffff;
  padding-left: 4.8%;
  font-style: italic;
  padding-bottom:5%;
  font-size: 14px;
 
}

.landing-banner{
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;
  margin-left: 9%;
 
  margin-top: 2%;
}
.seperator-small{
    width: 50px;
    height: 3px;
    background: #e5e5e5 none repeat;
    margin-top: 40px;
    margin-bottom: 40px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.who-am-i{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 6%; 
  padding-bottom: 5%;
}
.who-am-i p{
font-family: 'Cairo';
margin-top: -35px;
color:#797979;
 width: 70%;
 font-size: 1.2rem;
 line-height: 2;
}
/* .lets-fix{
  display: flex;
  flex-direction: column;
} */

