
.reach-text b{
    display: block;
    text-align: center;
    padding: 5%;
    font-weight: normal;
    font-size: 2.15rem;
    color: #FD8023;
}
.reach-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    
  }
  img[alt=ReachBanner] {
    width: 100%;
    /* padding-top: 5%; */
    padding-bottom: 5%;
    margin-top:-2%;

}
  img[alt=Reach-logo] {
      display: block;
      margin-left: auto;
      margin-right: auto;
    width: 70%;
    /* padding-top: 3%; */
    padding-bottom: 5%;

}
img[alt=ReachScreens] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  width: 100%;
  /* padding-top: 3%; */
  padding-bottom: 5%;

}


  img[alt=ReachCardboard] {
    width: 120%;
    padding-top: 5%;
    padding-bottom: 5%;


}
img[alt=wireframe] {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;


}

.reach-text p{
    font-weight: normal;
    color: #797979;
}
.reach-text h5{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #FD8023;

}
.reach-text h6{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(255, 177, 59);
    text-align: center;

}