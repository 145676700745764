@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background{
   
    background-color: rgb(255, 255, 255);
  
    box-shadow: 0 0 19px rgba(0, 0, 0, 0.082);
 
}
.corners {
  font-family: 'Cairo';
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  
  /* padding-right: 40px; */
}
.corners * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.corners li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1em;
  padding: 0;
}
.corners a {
  padding: 0.5em 0.8em;
  margin: 0.2em 0;
  display: block;
  color: rgba(117, 117, 117, 0.856);
  position: relative;
  text-decoration: none;
}
.corners a:before,
.corners a:after {
  position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    /* background:rgb(34, 34, 34); */
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.corners a:before {
  left: 0px;
  top: 0px;
}
.corners a:after {
  right: 0px;
    bottom: 0px;
}
.corners a:hover,
.corners  a {
  color: #4d4d4d;
}

.corners a:hover:before,
.corners .current1 a:before,
.corners a:hover:after,
.corners .current1 a:after {
  opacity: 1;
  width: 100%;
 
}


.current{
    /* -webkit-transform: scale(1.1); */
    /* transform: scale(1.1); */
    
    /* border-bottom: 2px solid rgb(54, 54, 54); */
    /* border-bottom: 2px solid rgb(34, 34, 34); */
    /* border-radius: 5px; */
    /* color: rgb(34, 34, 34); */
    /* height: 2.6em; */
    /* background: rgba(219, 219, 219, 0.384); */
    /* border-radius: 5px; */
  
    /* -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    opacity: 1; */
    /* border-bottom: 3px solid #000000; */
    font-weight: 1000;
    letter-spacing: 1px;
    font-size: 1.1em;
}







.App {
  text-align: center;
}
.MuiTabs-flexContainer {
  display: flex;
  justify-content: flex-end;
}
.nameInCorner{
  margin-right:10rem;
}
u {    
  border-bottom: 2px dotted rgb(105, 105, 105);
  text-decoration: none;
}
a {
  text-decoration: none;
  color: rgb(58, 58, 58);
  font-family: "Cairo";
}
.noscroll{
  overflow: hidden;

  
}
.waves{
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform-origin: -1 0;
          transform-origin: -1 0;
  margin-top: -5.5em;
  
  /* overflow-x: hidden; */
  overflow: hidden;
}
.waves path {
  -webkit-animation: 7s ease-in-out infinite alternate waveAnimateA;
          animation: 7s ease-in-out infinite alternate waveAnimateA;
  
}
.waves path:nth-child(2) {
  -webkit-animation: 7s ease-in-out infinite alternate waveAnimateB;
          animation: 7s ease-in-out infinite alternate waveAnimateB;
}
.waves path:last-child {
  -webkit-animation: 7s ease-in-out infinite alternate waveAnimateC;
          animation: 7s ease-in-out infinite alternate waveAnimateC;
}
@-webkit-keyframes waveAnimateA {
  0% {
    -webkit-transform: translate(-80px, 30px);
            transform: translate(-80px, 30px);
    fill: #fff9c4
  }
  100% {
    -webkit-transform: translate(80px, 0);
            transform: translate(80px, 0);
    fill: #f0f4c3;
  }
}
@keyframes waveAnimateA {
  0% {
    -webkit-transform: translate(-80px, 30px);
            transform: translate(-80px, 30px);
    fill: #fff9c4
  }
  100% {
    -webkit-transform: translate(80px, 0);
            transform: translate(80px, 0);
    fill: #f0f4c3;
  }
}
@-webkit-keyframes waveAnimateB {
  0% {
    -webkit-transform: translate(-80px, 15px);
            transform: translate(-80px, 15px);
    fill: rgb(241, 240, 221);
  }
  100% {
    -webkit-transform: translate(80px, 0);
            transform: translate(80px, 0);
    fill: #FFFDE7;
  }
}
@keyframes waveAnimateB {
  0% {
    -webkit-transform: translate(-80px, 15px);
            transform: translate(-80px, 15px);
    fill: rgb(241, 240, 221);
  }
  100% {
    -webkit-transform: translate(80px, 0);
            transform: translate(80px, 0);
    fill: #FFFDE7;
  }
}
@-webkit-keyframes waveAnimateC {
  0% {
    -webkit-transform: translate(-20px, 15px);
            transform: translate(-20px, 15px);
    fill:#fff59d;
  }
  100% {
    -webkit-transform: translate(-80px, 10px);
            transform: translate(-80px, 10px);
    fill:#e6ee9c;
  }
}
@keyframes waveAnimateC {
  0% {
    -webkit-transform: translate(-20px, 15px);
            transform: translate(-20px, 15px);
    fill:#fff59d;
  }
  100% {
    -webkit-transform: translate(-80px, 10px);
            transform: translate(-80px, 10px);
    fill:#e6ee9c;
  }
}
.tabsRec{
  display: flex;
  justify-content: flex-end;
  
}
.Desc{
  padding: 10%;
  line-height: 200%;
  color: #797979 ;
}
.website{
  padding: 0.5%;
  cursor: pointer;
}
.bannerSize {
  display: block;
  width: 90%;
}
.contact {
  font-family: 'Cairo';
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-style: italic;
  padding-top: 2%;
  line-height: 200%;
  
  width: 50%;
  /* transform: translateZ(-100); */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.contact a:hover {
  display: block;
  font-family: 'Cairo';
  /* background: rgb(196, 196, 196);
  opacity: 0.3; */
  height: 30px;
  width: auto;
  font-weight: bold;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: translate(20px);
  /* transform: translateZ(-100); */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}


/* .contact a:before
{
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0px;
    top: 0px;
    content: '';
    background: #000;
    opacity: 0.3;
    transition: all 0.3s;
}
.contact a:hover:before
{
    width: 100%;
} */

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.Menu-Items {
  padding: 20px;
}
.Menu-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.project-navbar {
  background-color: #464646;
}

.project-navbar {
  background-color: #464646;
}

.about-text {
  font-family: 'Cairo';
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 170%;
  top: 34%;
  left: 21%;
  width: 50%;
}

.pull-right {
  float: right !important;
}

.recent-all-tabs{
  display: flex;
  justify-content: flex-end;
}

.tile {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow 0.3s ease-out, opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
    opacity 0.2s ease-out;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
    opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  border-radius: 4px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  /* margin-top: 3%; */
}
.tile:hover {
  opacity: 1 !important;
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  -webkit-transform: translate(0, -4px);
          transform: translate(0, -4px);
  z-index: 999;
  

}
.tile:hover #toRead{
  color: #919191;
  padding-left: 4.8%;
  font-style: italic;
  padding-bottom:5%;
  font-size: 14px;
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in;
}

.tile-desc {
  
  font-family: 'Cairo';
  line-height: 170%;
  padding: 30px 15px 20px 15px;
}
.tile-tags{
  font-family: 'Cairo';
  background: rgba(105, 105, 105, 0.144);
  color: #474747;
  border-radius: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  font-size: 0.7em;
  padding-right: 15px;
  margin-right: 15px;
  /* padding-bottom: 49px; */
}
.tags{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: #797979;
  padding-left: 15px;
}
.title-tags{
  font-family: 'Cairo';
  background: #ffffff;
  border-radius: 5px;
  /* padding: 15px; */
  color: #797979;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -45px;
  display: block;
  position: absolute;
  font-size: 1.2em;
}
.card-height{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 35px;
}
.time-read{
  display: block;
  padding-left: 80%;
  font-style: italic;
  width:100%;
 
  /* border: #000000 solid; */
  color: #aaa9a9;
  font-size: 0.8em;
}
hr{
  width: 100%;
  opacity: 0.1;
  color:aliceblue;
}
.text-link:hover {
  /* background-color: #636363;
  transition: all 0.25s cubic-bezier(0.1, 0.29, 0, 1);
  opacity: 1; 
  transition: all 0.25s cubic-bezier(0.1, 0.29, 0, 1);
  padding: 1rem;
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.1);
  border-radius: 10px;  */
  /* background-color: #a0a0a0;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent; */
}
.navbar-background {
  background-color: #fffefe;
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: #000000;
  padding: 2%;
}

.project-nav-background {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 3%;
  width: 100%;
  position: fixed;
  top: 5%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.concepts-used {
  position: fixed;
  left: 60%;
  width: 100%;
  bottom: 50%;
}
.textMuted {
  /* display: flex; */
  text-align: left;
  align-items: initial;
  padding-left: 16%;
  padding-bottom: 1%;
  margin: auto;
  justify-content: space-around;
  color: grey;
}
.text {
  text-align: left;
  padding-left: 16%;
  padding-bottom: 3%;
  /* display: flex; */
  margin: inherit;
  justify-content: space-around;
}

.share {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 26px;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.35s 0.2s;
  transition: opacity 0.35s 0.2s;
  z-index: 2;
  display: block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 12px;
  color: #4f575b;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 5px;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: color 0.35s, background-color 0.35s;
  transition: color 0.35s, background-color 0.35s;
}
[className^="icon-"],
[className*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.bottom-text {
  color: rgba(179, 192, 200, 0.8);
  opacity: 0;
  font-size: 11px;
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  text-align: left;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}
.column1 {
  padding-left: 100px;
}
.iconList {
  position: fixed;
  width: 35%;
  -webkit-transform: translate(168%, 2900%);
          transform: translate(168%, 2900%);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.LinkedIn {
  color: #0077b5;
  size: 50px;
}
.Facebook {
  color: #3c5a99;
}
.Mail {
  color: #0072c6;
}

.name {
  position: fixed;
  top: 20%;
  left: 24%;
}
.paintingStuff {
  display: block;
  position: absolute;
  left: 33%;
  top: 10%;
}
.about {
  display: inline;
  overflow: hidden;
  position: fixed;
  width: 35%;
  -webkit-transform: translate(40%, 180%);
          transform: translate(40%, 180%);
  max-height: 800px;
}
.Intro {
  font-family: "Cairo";
  margin: auto;
  margin-top: 3em;
 
  color: #797979 ;
  /* margin-left: 8%;  */
  width: 50%;
  line-height: 170%;
}
.Intro h3{

  color:#393939;
}
.Contents {
  margin-top: 10rem;
  margin-left: 15rem;
  width: 100%;
}
.contact-list {
  font-family: 'Cairo';
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;

  display: flex;
  flex-direction: column;

  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.replaceWithFooter{
  padding-bottom: 3%;
}
.footer{
  border-top: #cccccc 0.5px solid;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: 'Cairo';
  display: flex;
  justify-content: space-evenly;
    /* margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    margin-bottom:1em;
    font-size: 1.5em; */
}
.head-me{
  width: 50%;
}
.currentFooter{

  color: #383838;

}
@media screen and (min-width: 400px){
  .avatar{
    width: 100%;
  }
}
.avatar{
  width: 40%;
  /* margin-left: auto;
  margin-right:auto; */

}
.landing-banner h3{
  margin-right: auto;
  font-weight: 400;
  font-size: 3.7rem;
  margin-bottom: -20px;
  
  color: #383838;
  font-family: 'Neucha';
}
.workMargins{
  margin-top: -144px;
}
.projectsBar{
  font-family: 'Cairo';
font-weight: 800;
color: #797979;
position:fixed;
padding-top: 2%;

}
#toRead{
  color: #ffffff;
  padding-left: 4.8%;
  font-style: italic;
  padding-bottom:5%;
  font-size: 14px;
 
}

.landing-banner{
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  align-items: center;
  margin-left: 9%;
 
  margin-top: 2%;
}
.seperator-small{
    width: 50px;
    height: 3px;
    background: #e5e5e5 none repeat;
    margin-top: 40px;
    margin-bottom: 40px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}
.who-am-i{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 6%; 
  padding-bottom: 5%;
}
.who-am-i p{
font-family: 'Cairo';
margin-top: -35px;
color:#797979;
 width: 70%;
 font-size: 1.2rem;
 line-height: 2;
}
/* .lets-fix{
  display: flex;
  flex-direction: column;
} */


#descIllus{
        
        color: #ffffff;
        font-size: 14px;
        line-height: 1.9;
}
.tileIllus:hover #descIllus{
    color: #919191;
    /* padding-left: 4%; */
    /* padding-bottom:5%; */
    font-size: 14px;
    line-height: 1.5;
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
  }
.tileIllus {
    /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05); */
    -webkit-transition: box-shadow 0.3s ease-out, opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
      opacity 0.2s ease-out;
    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out,
      opacity 0.2s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    border-radius: 4px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    background-color: #fff;
    overflow: hidden;
    /* height: 100%; */
    /* margin-top: 3%; */
  }
  .descriptionText{
    font-family: 'Cairo';
    /* margin-top: -35px; */
    margin-left: 'auto';
    margin-right: 'auto';
    color:#797979;
     width: 70%;
     font-size: 1rem;
     line-height: 2;
    }
.myName{
    color: #383838;
    font-size: 2em;
    font-weight: 400;
    padding-bottom: 8%;
}
.about-flex{
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top:-4%;
    max-width: 80%;
    /* padding-bottom: 5%; */
}
.Intro-About{
    font-family: "Cairo";
    /* margin: auto; */
    /* margin-top: 3em; */
    color: #797979;
    margin-top: 4%;
    width: 50%;
    line-height: 170%;
}
.for-contact{
   
    display: flex;
    flex-direction: column;
    
}
.paints{
    font-family: 'Cairo';
    font-size: 1em;
    color:#797979;
}
.PaintsPlacement{
    display: flex;
    width: 79%;
    justify-content: flex-end;
    
}
.largerpaints{
    font-family: 'Cairo';
    font-size: 2em;
    font-weight: 400;
    color:#383838;
}
.outerGrid{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-content: center;
   margin-left: 14.7%;
  
   
   
}
.imagesAbout{
    width:250px;
    height: 300px;
    padding: 3%;
    border-radius: 50px;
}
.imagesGanesha{
    width:450px;
    height: 350px;
    padding: 3%;
    border-radius: 50px;
}
.Contacts{
    margin-left: 17%;
}
.ContactsLinks{
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 11.5%;
    
}
.ContactsLinks a{
    border: #000000 1.5px solid;
    padding: 0.5%;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
}
.lastPara{
    font-family: "Cairo";
    color: #797979;
    margin-left: 17%;
    width: 40%;
    line-height: 170%;
    padding-bottom: 3%;

}
.progress-container {
    z-index: 100;
    position: fixed;
    top: 55%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Roboto';
    display: block;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0.3, 0, 1);
    transition: all 0.3s cubic-bezier(0.7, 0.3, 0, 1);
    border-radius: 2px;
    border: 1px rgba(202, 202, 202, 0.521) solid;

}

.progress-container a {
      display: block;
      text-align: left;
      cursor: pointer;
      margin: 0.5rem 1rem;
      opacity: 0.3; }

.progress-container:hover {
    background-color: white; }
  
.progress-responsive-position {
      right: 5%; }

.progress-container a:hover {
        opacity: 1; }

.progress-container .circle {
          display: none;
          margin-left: 0.5rem; }

.progress-container .circle:before {
          content: '\25CF'; }
        
.progress-container .scroll-active {
         opacity: 1;
         color: black;
         }

.label {
     text-align: right; }
  
  
.PaastoPrev{
    display: flex;
    justify-content: space-between;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    margin-bottom:1em;
    font-size: 2em;
    
}
.PaastoEnd{
    display: flex;
    justify-content: space-evenly;
    margin-left: 5em;
    margin-right: 5em;
    margin-top: 2em;
    /* margin-bottom:1em; */
    font-size: 1.5em;
    border-top: 0.5px rgba(153, 153, 153, 0.267)solid;
    
    
  }
  
  .newstyling{
    padding: 1%;
    padding-top: 2%;
    color: #797979;
    opacity: 0.2;
  }
  .newstyling:hover{
    color: #393939;
    opacity: 1;
  }
  

.paasto-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    /* position: absolute;
      height: fit-content;
      font-family: 'Roboto';
      line-height: 170%;
      top: 14%;
      left: 17%;
      width: 50%; */
  }
.paasto-text h3{
    text-align: center;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(253, 130, 125);
}
.paasto-text b{
  display: block;
  text-align: center;
  padding: 5%;
  /* font-family: 'lato'; */
  font-weight: normal;
  font-size: 2.15rem;
  color: rgb(253, 130, 125);
}
.paasto-text h5{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(250, 145, 141);
}
.paasto-text h6{
    font-weight: medium;
    text-align: center;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #383838;
}
.paasto-text p{
    font-weight: normal;
    color: #797979;
}

.paasto-text img {
    border-radius: 14px;
}
.paasto-text blockquote {
    color: #797979;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em rgb(252, 231, 230) solid;
  }

img[alt=PaastoScreen1] {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;

}
img[alt=PaastoScreenL]{
position: relative; 
right: 30%;   
width: 170%;
    
  }
img[alt=PaastoScreen3]{
    width: 100%;
    /* position: relative */

}
img[alt=PaastoScreen2] {
    position: relative;
    left: 23%; 
    width: 50%
}
img[alt=Paasto-logo] {
    position: relative;
    left: 20%;
    width:60%;
  }
img[alt=PaastoResults]{
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    width:70%;
}
img[alt=PaastoScreenPen]{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

  

.reach-text b{
    display: block;
    text-align: center;
    padding: 5%;
    font-weight: normal;
    font-size: 2.15rem;
    color: #FD8023;
}
.reach-text{
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    
  }
  img[alt=ReachBanner] {
    width: 100%;
    /* padding-top: 5%; */
    padding-bottom: 5%;
    margin-top:-2%;

}
  img[alt=Reach-logo] {
      display: block;
      margin-left: auto;
      margin-right: auto;
    width: 70%;
    /* padding-top: 3%; */
    padding-bottom: 5%;

}
img[alt=ReachScreens] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  width: 100%;
  /* padding-top: 3%; */
  padding-bottom: 5%;

}


  img[alt=ReachCardboard] {
    width: 120%;
    padding-top: 5%;
    padding-bottom: 5%;


}
img[alt=wireframe] {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;


}

.reach-text p{
    font-weight: normal;
    color: #797979;
}
.reach-text h5{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #FD8023;

}
.reach-text h6{
    font-weight: normal;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(255, 177, 59);
    text-align: center;

}
.PaastoEnd{
  display: flex;
  justify-content: space-evenly;
  margin-left: 5em;
  margin-right: 5em;
  margin-top: 2em;
  /* margin-bottom:1em; */
  font-size: 1.5em;
  border-top: 0.5px rgba(153, 153, 153, 0.267)solid;
  
  
}

.newstyling{
  padding: 1%;
  padding-top: 2%;
  color: #797979;
  opacity: 0.2;
}
.newstyling:hover{
  color: #393939;
  opacity: 1;
}

.FriendMap h3{
    
    margin-left: auto;
    margin-right: auto;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.friendMapBanner{
    width: 70%;
    display: block;
     margin-left: auto;
     margin-right: auto;
     
}
.FriendMap {
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    /* position: absolute;
      height: fit-content;
      font-family: 'Roboto';
      line-height: 170%;
      top: 14%;
      left: 17%;
      width: 50%; */
  }
.FriendMap h3{
    text-align: center;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.FriendMap b{
  display: block;
  text-align: center;
  padding: 5%;
  /* font-family: 'lato'; */
  font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.FriendMap h5{
    font-weight: normal;
    
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(20, 140, 184);
}
.FriendMap h6{
    font-weight: medium;
    text-align: center;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #383838;
}
.FriendMap p{
    /* padding-top: 6em; */
    font-weight: normal;
    color: #797979;
}

.FriendMap img {
    /* padding-bottom: 5%;
    padding-left: 5%; */
}
.FriendMap blockquote {
   
    margin: 0;
    margin-top:8em;
    margin-bottom:16em; 
    width:100%;
    padding-left: 3em;
    
    
  }
.pstyle{
    /* color: rgb(0, 187, 255); */
    margin: 0;
    margin-bottom:3em; 
    padding-right: 2em;
    line-height: 170%;
    font-family: 'Roboto';
    font-weight: normal;
    color: #797979;
    /* padding-top: 1.5%; */
    padding-left: 3em;

}
.gifContainer{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.aroundspace{
    display: block;
    padding: 3em;
}
.Friendmapr {
    
    width: 70%;
    /* padding-top: 10em; */
    padding: 5%;
    /* padding-bottom:5%; */
  
    float: right;

}
.Friendmapl {
    width: 70%;
   
    padding-top: 2em;
    padding-right: 4%;
    padding-bottom:5%;
    float: left;

}

img[alt=Friendmap]{
  padding-top: 5%; 
  padding-bottom: 5%; 
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
img[alt=FriendmapUG]{
  /* padding-top: 3%;  */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:-2em;
  width: 100%;
}
img[alt=FriendmapPlan]{
  /* padding-top: 3%;  */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:1em;
  width: 100%;
}
img[alt=FriendmapS]{
margin-right: 5em;
   width: 120%;
   -webkit-transition: all .5s ease-out;
   transition: all .5s ease-out;
   
  }
  img[alt=FriendmapS]:hover{
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    -webkit-transition: all .5s cubic-bezier(.1,.29,0,1);
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);

  }
  


img[alt=Friendmapl]{
    width: 100%;
}
    /* padding-top: 2em; */
    /* padding-right: 4%; */
    /* padding-bottom:5%; */
    /* float: left;
  } */
 img[alt=Friendmapr1]{
    width: 100%;
 }
    /* padding-top: 5em;
    padding-right: 4%;  */
    /* padding-bottom:5%; */
    /* float: right;
  } */
  img[alt=Friendmapr2]{
    width: 100%;
  }
    /* padding-top: 5em; */
    /* padding-right: 4%; */
    /* padding-bottom:5%; */
    /* float: right;
  } */
  .AligingGifs{
    display: flex;
    flex-direction: column;
    align-content: space-around;
  }
  .sideAlign{
    display: flex;
    align-content: space-around;
    margin-top:4em;
    margin-bottom: 2em;

  }
  .sideAlignL{
    display: flex;
    flex-direction: row-reverse;
    align-content: space-around;
    margin-top:4em;
    margin-bottom: 2em;
  }
  .toTheLeft{
    display: block;
    position: relative;
    margin-top: 4em;
    margin-left: 2em;
  }
  .toTheRight{
    display: block;
    position: relative;
    margin-top: 3em;
    margin-right: 2em;
  }
