.PaastoEnd{
  display: flex;
  justify-content: space-evenly;
  margin-left: 5em;
  margin-right: 5em;
  margin-top: 2em;
  /* margin-bottom:1em; */
  font-size: 1.5em;
  border-top: 0.5px rgba(153, 153, 153, 0.267)solid;
  
  
}

.newstyling{
  padding: 1%;
  padding-top: 2%;
  color: #797979;
  opacity: 0.2;
}
.newstyling:hover{
  color: #393939;
  opacity: 1;
}

.FriendMap h3{
    
    margin-left: auto;
    margin-right: auto;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.friendMapBanner{
    width: 70%;
    display: block;
     margin-left: auto;
     margin-right: auto;
     
}
.FriendMap {
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 200%;
    width: 50%;
    /* position: absolute;
      height: fit-content;
      font-family: 'Roboto';
      line-height: 170%;
      top: 14%;
      left: 17%;
      width: 50%; */
  }
.FriendMap h3{
    text-align: center;
    /* font-family: 'lato'; */
    font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.FriendMap b{
  display: block;
  text-align: center;
  padding: 5%;
  /* font-family: 'lato'; */
  font-weight: normal;
    font-size: 2.15rem;
    color: rgb(62, 179, 221);
}
.FriendMap h5{
    font-weight: normal;
    
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: rgb(20, 140, 184);
}
.FriendMap h6{
    font-weight: medium;
    text-align: center;
    /* opacity: 0.5;     */
    font-size: 1.15rem;
    color: #383838;
}
.FriendMap p{
    /* padding-top: 6em; */
    font-weight: normal;
    color: #797979;
}

.FriendMap img {
    /* padding-bottom: 5%;
    padding-left: 5%; */
}
.FriendMap blockquote {
   
    margin: 0;
    margin-top:8em;
    margin-bottom:16em; 
    width:100%;
    padding-left: 3em;
    
    
  }
.pstyle{
    /* color: rgb(0, 187, 255); */
    margin: 0;
    margin-bottom:3em; 
    padding-right: 2em;
    line-height: 170%;
    font-family: 'Roboto';
    font-weight: normal;
    color: #797979;
    /* padding-top: 1.5%; */
    padding-left: 3em;

}
.gifContainer{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.aroundspace{
    display: block;
    padding: 3em;
}
.Friendmapr {
    
    width: 70%;
    /* padding-top: 10em; */
    padding: 5%;
    /* padding-bottom:5%; */
  
    float: right;

}
.Friendmapl {
    width: 70%;
   
    padding-top: 2em;
    padding-right: 4%;
    padding-bottom:5%;
    float: left;

}

img[alt=Friendmap]{
  padding-top: 5%; 
  padding-bottom: 5%; 
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
img[alt=FriendmapUG]{
  /* padding-top: 3%;  */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:-2em;
  width: 100%;
}
img[alt=FriendmapPlan]{
  /* padding-top: 3%;  */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:1em;
  width: 100%;
}
img[alt=FriendmapS]{
margin-right: 5em;
   width: 120%;
   transition: all .5s ease-out;
   
  }
  img[alt=FriendmapS]:hover{
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    -webkit-transition: all .5s cubic-bezier(.1,.29,0,1);
    -o-transition: all .5s cubic-bezier(.1,.29,0,1);
    transition: all .5s ease-in;
    transform: scale(1.5);

  }
  


img[alt=Friendmapl]{
    width: 100%;
}
    /* padding-top: 2em; */
    /* padding-right: 4%; */
    /* padding-bottom:5%; */
    /* float: left;
  } */
 img[alt=Friendmapr1]{
    width: 100%;
 }
    /* padding-top: 5em;
    padding-right: 4%;  */
    /* padding-bottom:5%; */
    /* float: right;
  } */
  img[alt=Friendmapr2]{
    width: 100%;
  }
    /* padding-top: 5em; */
    /* padding-right: 4%; */
    /* padding-bottom:5%; */
    /* float: right;
  } */
  .AligingGifs{
    display: flex;
    flex-direction: column;
    align-content: space-around;
  }
  .sideAlign{
    display: flex;
    align-content: space-around;
    margin-top:4em;
    margin-bottom: 2em;

  }
  .sideAlignL{
    display: flex;
    flex-direction: row-reverse;
    align-content: space-around;
    margin-top:4em;
    margin-bottom: 2em;
  }
  .toTheLeft{
    display: block;
    position: relative;
    margin-top: 4em;
    margin-left: 2em;
  }
  .toTheRight{
    display: block;
    position: relative;
    margin-top: 3em;
    margin-right: 2em;
  }