@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
.background{
   
    background-color: rgb(255, 255, 255);
  
    box-shadow: 0 0 19px rgba(0, 0, 0, 0.082);
 
}
.corners {
  font-family: 'Cairo';
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  
  /* padding-right: 40px; */
}
.corners * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.corners li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1em;
  padding: 0;
}
.corners a {
  padding: 0.5em 0.8em;
  margin: 0.2em 0;
  display: block;
  color: rgba(117, 117, 117, 0.856);
  position: relative;
  text-decoration: none;
}
.corners a:before,
.corners a:after {
  position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    /* background:rgb(34, 34, 34); */
    transition: all 0.3s;
}
.corners a:before {
  left: 0px;
  top: 0px;
}
.corners a:after {
  right: 0px;
    bottom: 0px;
}
.corners a:hover,
.corners  a {
  color: #4d4d4d;
}

.corners a:hover:before,
.corners .current1 a:before,
.corners a:hover:after,
.corners .current1 a:after {
  opacity: 1;
  width: 100%;
 
}


.current{
    /* -webkit-transform: scale(1.1); */
    /* transform: scale(1.1); */
    
    /* border-bottom: 2px solid rgb(54, 54, 54); */
    /* border-bottom: 2px solid rgb(34, 34, 34); */
    /* border-radius: 5px; */
    /* color: rgb(34, 34, 34); */
    /* height: 2.6em; */
    /* background: rgba(219, 219, 219, 0.384); */
    /* border-radius: 5px; */
  
    /* -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    opacity: 1; */
    /* border-bottom: 3px solid #000000; */
    font-weight: 1000;
    letter-spacing: 1px;
    font-size: 1.1em;
}





